import axios from "axios";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import swal from "sweetalert";
import plus from "../../assets/Icon/plus.svg";
import { BASE_URL } from "../Const/Url";
import DeleteIcon from "../CustomCommons/DeleteIcon";
import EditIcon from "../CustomCommons/EditIcon";
import Loader from "../CustomCommons/Loader";
import NextIcon from "../CustomCommons/NextIcon";
import Permission from "../CustomCommons/Permission";
import PreviousIcon from "../CustomCommons/PreviousIcon";
import kaarujLogo from "../../assets/Icon/KAARUJ 1.png";
import {
  can_add_invoice,
  can_change_invoice,
  can_delete_invoice,
  can_view_invoice,
  has_permissions,
} from "../CustomCommons/utils";
import ViewIcon from "../CustomCommons/ViewIcon";
import * as path from "../Routes/RoutePaths";
import { showToast } from "../../utils/ToastHelper";
import { logout_func2 } from "../Const/logoutCommon";
import AddresTopLow from "./AddresTopLow";
import HelperExportComponent from "../../utils/HelperExportComponent";

const AreaInvoiceReport = () => {
  const [isLoading, SetisLoading] = useState(true);
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const cRef = useRef();
  const [query, setQuery] = useState("");

  // ofset code
  const [totalData, setTotalData] = useState(0);
  const [items_data, setItems_data] = useState([]);
  const [total_item, setTotal_item] = useState(0);
  const [isLoading2, SetisLoading2] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 20;
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setInvoiceList(res.data.data.results);

        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        // console.log(res.data);
        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  useEffect(() => {
    apiFunction(
      `${BASE_URL}${"api/v1/sales/inventory/invoice-area-report/"}?limit=${limit}&offset=${offset}&address_filter=${query}`
    );
  }, []);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);
    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
    // SetisLoading(false)
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
    // SetisLoading(false)
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);

  useEffect(() => {
    if (pageNumber > 1 || query) {
      apiFunction(
        `${BASE_URL}${"api/v1/sales/inventory/invoice-area-report/"}?limit=${limit}&offset=${offset}&address_filter=${query}`
      );
    }
  }, [pageNumber, query]);

  // ofset code

  const history = useHistory();
  const updatedPageNumber = parseInt(
    localStorage.getItem("invoicePageNbr")
      ? localStorage.getItem("invoicePageNbr")
      : 0
  );
  // const [pageNumber, setPageNumber] = useState(updatedPageNumber);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;

  const [invoiceList, setInvoiceList] = useState([]);
  const invoice_choices = ["Paid", "Unpaid", "Due"];

  const delivery_choices = [
    "Returned",
    "Recieved",
    "Delivered",
    "Pending",
    "Hold",
    "Dispached",
  ];
  const delivery_type_choices = ["Regular", "Urgent"];
  const paymentType = [
    "C.O.D",
    "Card",
    "Bank Transfer",
    "bKash",
    "SSLECOMMERZ",
    "Nagad",
    "KOD",
  ];
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${month}-${day}-${year}`;
  var options = {
    day: "numeric",
    year: "numeric",
    month: "numeric",
  };
  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Daily Sales Report",
    // onAfterPrint: () => swal("Printed"),
    bodyClass: "dis",
  });

  const getInvoiceList = () => {
    let url;
    if (date1 && date2) {
      url = `${BASE_URL}api/v1/sales/inventory/invoice/filter/?start=${date1}&end=${date2}`;
    } else {
      url = `${BASE_URL}api/v1/sales/inventory/invoice/`;
    }

    axios
      .get(url)
      .then((res) => {
        setInvoiceList(res.data.data.results);
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  useEffect(() => {
    // getInvoiceList();
  }, []);
  const getReport = () => {
    let url;
    if (date1 && date2) {
      url = `${BASE_URL}${"api/v1/sales/inventory/invoice-area-report/"}?limit=${limit}&offset=${offset}&address_filter=${query}&start=${date1}&end=${date2}`;
    } else {
      url = `${BASE_URL}${"api/v1/sales/inventory/invoice-area-report/"}?limit=${limit}&offset=${offset}&address_filter=${query}`;
    }

    axios
      .get(url)
      .then((res) => {
        console.log(res?.data);
        setInvoiceList(res.data.data.results);
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response)?.code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  const generateData = () => {
    if (!date1 && !date2) {
      swal("Please select two dates");
    } else if (
      (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") ||
      (date1 === "" && date2 === "")
    ) {
      swal("Please select two dates");
    } else if (date1 === "undefined/undefined/" || date1 === "") {
      swal("Please select  start Date");
    } else if (date2 === "undefined/undefined/" || date2 === "") {
      swal("Please select  end Date");
    } else {
      getReport();
    }
  };

  // console.log(data);

  const keys = ["number", "prepared_by", "delivery_status", "invoice_date"];
  const getData = invoiceList.filter((p) =>
    keys.some((key) => p[key]?.toString()?.toLowerCase()?.includes(query))
  );

  useEffect(() => {
    // getInvoiceList();
    if (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") {
      swal("Please select  dates");
    }
  }, []);

  // const changePage = ({ selected }) => {
  //   setPageNumber(selected);
  //   localStorage.setItem("invoicePageNbr", selected);
  // };
  // console.log(invoiceList);
  if (!has_permissions(can_view_invoice)) {
    return <Permission />;
  }
  if (isLoading) {
    return <Loader />;
  }

  const sourceList = ["Website", "Inventory", "Admin"];

  return (
    <>
      <div>
        <div className="categorey-parent">
          <div className="cotagorey-head mb-4">
            <div>
              <h3>Address Search </h3>
              <input
                style={{ color: "black" }}
                type="text"
                className="filter-input"
                placeholder="Search here"
                onChange={(e) => {
                  setQuery(e.target.value.toLocaleLowerCase());
                }}
              />
            </div>
            <div className="filter_div">
              <input
                type="date"
                format="DD-MM-YYYY"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;
                  setDate1(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
              <span className="mx-2 my-auto" placeholder="09-09-2022">
                {" "}
                To
              </span>
              <input
                type="date"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;

                  setDate2(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
              <div
                className="button-div my-auto ms-2 px-3 py-2 text-white rounded"
                style={{ background: "black", cursor: "pointer" }}
                onClick={generateData}
              >
                <span> Generate</span>
              </div>
              <div
                // className="button-div my-auto mx-2 "
                className="button-div my-auto ms-2 px-3 py-2 text-white rounded"
                style={{ background: "black", cursor: "pointer" }}
              >
                {/* <a
                    rel="noreferrer"
                    style={{ color: "white" }}
                    href={`${BASE_URL}${"api/v1/sales/inventory/invoice-area-report/"}?export=true&address_filter=${query}`}
                    target="_blank"
                  >
                    Export
                  </a> */}
                <HelperExportComponent
                  url={`${BASE_URL}${"api/v1/sales/inventory/invoice-area-report/"}?limit=${limit}&offset=${offset}&address_filter=${query}&start=${date1}&end=${date2}&export=true`}
                  fileName={"area_report_export_file.xlsx"}
                  items_data={items_data}
                />
              </div>
            </div>
          </div>
          {/* categorey list table */}
          <div style={{ display: "block", overflowX: "auto" }}>
            <table className="table_my">
              <thead
                className="bg-dark text-white head-row"
                style={{
                  height: "50px",
                  borderTopLeftRadius: "20px !important",
                }}
              >
                <tr className="">
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "166px",
                    }}
                  >
                    <p className="ms-3 my-auto">Invoice No</p>
                  </th>
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "187px",
                    }}
                  >
                    Product Name
                  </th>
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "180px",
                    }}
                  >
                    Delivery Status
                  </th>
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "147px",
                    }}
                  >
                    Quantity
                  </th>

                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "120px",
                    }}
                  >
                    Total
                  </th>

                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "130px",
                    }}
                  >
                    Address
                  </th>
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "110px",
                    }}
                  >
                    Create on
                  </th>
                </tr>
              </thead>
              <tbody className="tb">
                {items_data.length > 0 && items_data ? (
                  items_data.map((category, index) => (
                    <tr className="table-row " key={category?.slug}>
                      <td>
                        <p className="ms-3 my-auto">
                          {category.invoice_number}
                        </p>
                      </td>
                      <td>{category.product_name}</td>
                      <td>{category.delivery_status}</td>

                      <td>{category.quantity}</td>

                      <td>৳ {category?.total}</td>

                      <td className="p-1">{category?.address}</td>
                      <td>{category.invoice_date}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colspan="9">
                      <div className="not_found d-flex justify-content-center align-items-center py-4">
                        <h4 className="my-4">No Data Found</h4>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* entites and paggination */}
          {totalData > 0 && (
            <div className="row pag" style={{ marginTop: "30px" }}>
              <div className="col-6 col-md-5">
                <p
                  className="inter"
                  style={{ color: "#AEAEB2", fontSize: "16px" }}
                >
                  Total Entries:{" "}
                  <span style={{ color: "black", fontSize: "14px" }}>
                    {totalData}
                  </span>
                  <span className="ms-2"></span>
                  Total Page:{" "}
                  <span style={{ color: "black", fontSize: "14px" }}>
                    {Math.ceil(totalData / 20)}
                  </span>
                </p>
              </div>
              {/* <div className="col-12 col-md-6 d-flex paggination-button">
              <ReactPaginate
                previousLabel={<PreviousIcon />}
                nextLabel={<NextIcon />}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"a"}
                nextLinkClassName={"a"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                initialPage={query?.length > 0 ? 0 : updatedPageNumber}
                forcePage={
                  query?.length > 0 || getData?.length < usersPerPage
                    ? 0
                    : updatedPageNumber
                }
              />
            </div> */}
              <div className="col-12 col-md-6 d-flex paggination-button">
                <>
                  <div className="row lead_pagination bg-transparent">
                    <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
                      <nav aria-label="...">
                        <ul className="pagination pagination-sm">
                          {!isLoading ? (
                            <li className="page-item ">
                              {prevUrl !== null ? (
                                <Link
                                  onClick={handlePaginationPrevious}
                                  className={` ${
                                    prevUrl === null ? "disabled" : ""
                                  } page-link pagination_texts  pagination_btn font_size_for_small py-1 mt-1 me-3 a`}
                                  to="#"
                                  tabindex="-1"
                                >
                                  <PreviousIcon />
                                </Link>
                              ) : (
                                <Link
                                  onClick={() =>
                                    showToast(
                                      "success",
                                      "You are on first page"
                                    )
                                  }
                                  className={` ${
                                    prevUrl === null ? "disabled" : ""
                                  } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                  to="#"
                                  tabindex="-1"
                                >
                                  <PreviousIcon />
                                </Link>
                              )}
                            </li>
                          ) : (
                            <li className="page-item ">
                              <Link
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            </li>
                          )}

                          {[...Array(pageCount === null ? 1 : pageCount)].map(
                            (elem, index) => (
                              <li
                                className="page-item"
                                style={{
                                  display: `${
                                    index + 1 !== pageNumber ? "none" : "block"
                                  }`,
                                }}
                              >
                                {/*  style={{
                      display: `${index + 1 !== pageNumber ? "none" : "block"}`,
                    }} */}
                                {/* setPageNumber(prev=> prev - 1) */}

                                <Link
                                  onClick={() =>
                                    handleChangeCurrentPage(index + 1)
                                  }
                                  className={`${
                                    index + 1 == pageNumber
                                      ? "paginationActive"
                                      : ""
                                  } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts px-2 text-dark`}
                                  to="#"
                                >
                                  <span className="px-1 py-1">
                                    {pageNumber}
                                  </span>
                                </Link>
                              </li>
                            )
                          )}
                          {!isLoading ? (
                            <li className="page-item">
                              {nextUrl !== null ? (
                                <Link
                                  onClick={handlePaginationNext}
                                  className={`${
                                    nextUrl === null ? "disabled" : ""
                                  } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                  to="#"
                                >
                                  <NextIcon />{" "}
                                </Link>
                              ) : (
                                <Link
                                  onClick={() =>
                                    showToast("success", "You are on last page")
                                  }
                                  className={`${
                                    nextUrl === null ? "disabled" : ""
                                  } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                  to="#"
                                >
                                  <NextIcon />
                                </Link>
                              )}
                            </li>
                          ) : (
                            <li className="page-item">
                              <Link
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />
                              </Link>
                            </li>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <AddresTopLow/> */}
    </>
  );
};

export default AreaInvoiceReport;
